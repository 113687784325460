import React from "react"

import { graphql } from "gatsby"
import {Link, useI18next, useTranslation} from "gatsby-plugin-react-i18next"

import Layout from "/src/components/layout"
import ContentLayout from "/src/components/content-layout"
import Seo from "/src/components/seo"
import AnchorLink  from "/src/components/link-anchor"
import Assets  from "/src/components/link-assets"
import AssetsDirect  from "/src/components/link-assets-direct"
import External from "/src/components/link-external"
import DataList from "/src/components/datalist"
import DataListRow from "/src/components/datalist-row"


const Page = ({data}) => {

  const bredcrumb = [
    {
      pathname: '/',
      crumbLabel: 'Home'
    },
  ]
  const {t} = useTranslation();
  const lang = data.locales.edges[0] ? data.locales.edges[0].node.language : 'ja' 

  return (
    <Layout bredcrumb={bredcrumb} lang={lang}>
      <Seo title="ウェブアクセシビリティ" lang={lang} />
      <ContentLayout lang={lang} onlyjatext={true}>
       <h1>ウェブアクセシビリティ</h1>
        <h2>ウェブアクセシビリティについて</h2>
        <p>「ウェブサイトを利用するすべての人が、心身の機能や年齢、利用する環境に関係なく、ウェブサイトで提供されている情報やサービスを利用できること」をウェブアクセシビリティといいます。利用者誰もが等しく情報へアクセスできることに配慮しながら、ページを作成し運営する考え方です。</p>
        <h2>ウェブアクセシビリティ方針</h2>
        <p>理化学研究所筑波キャンパスウェブサイトでは、誰もが負担なく情報を取得できるよう、日本産業規格JIS Ｘ8341‐3：2016「高齢者・障害者等配慮設計指針―第3部：ウェブコンテンツ」に基づき、アクセシビリティを継続的に向上していけるように努めています。</p>
        <h3>対象範囲</h3>
        <p>理化学研究所筑波キャンパスウェブサイト内（URLがhttps://tsukuba.riken.jpから始まるページ）</p>
        <h3>例外事項</h3>
        <p>サイト全体のうち、以下については対象外とします。</p>
        <ul>
          <li>外部より提供を受けている画像等</li>
          <li>PDFファイル</li>
          <li>研究成果の画像等および数式、化学式</li>
          <li>動画配信ページ</li>
          <li>利用者が限定されているウェブサイト</li>
          <li>アーカイブとして残しているウェブサイト</li>
          <li>FacebookなどのSNS</li>
        </ul>
        <h3>目標および達成する期限・適合レベル</h3>
        <p>
          期限：2022年3月31日<br/>
          適合レベル：JIS X 8341-3:2016の適合レベルAAに準拠（上記、例外事項除く）
        </p>
        <h2>試験結果</h2>
        <p>
          <Link to="/accessibility/20220128">JIS X 8341-3:2016附属書JBに基づく試験結果表示（2021年12月1日～2022年1月28日）</Link>
        </p>

      </ContentLayout>
    </Layout>
  ) 
}
export default Page

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    assets:allFile(filter: {sourceInstanceName: {eq: "assets"}}) {
      edges {
        node {
          base
          name
          extension
          prettySize
        }
      }
    }
  }
`;


